/* @import url("https://fonts.googleapis.com/css?family=Montserrat:400,800"); */

* {
  box-sizing: border-box;
}

/* body {
  background: #f6f5f7;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  height: 100vh;
  margin: -20px 0 50px;
} */

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.login p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-align: center;
  width: 100%;
}

.login span {
  font-size: 12px;
}

.login a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

.login button {
  border-radius: 20px;
  border: 1px solid #F7941D;
  background-color: #F7941D;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  margin-top: 20px;
}

.login button:active {
  transform: scale(0.95);
}

.login button:focus {
  outline: none;
}

.login button.ghost {
  background-color: transparent;
  border-color: #ffffff;
}
.login .logo {
  width: 200px;
  margin-bottom: 30px;
}
.login .logo img {
  width: 100%;
}
.login form {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

.login input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0px 3px 0px;
  width: 100%;
}

.login h1 {
  font-size: 34px;
  line-height: normal;
  margin-bottom: 0px;
}

.login .login_container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 768px;
  min-height: 480px;
}

.login .form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.login .sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.login .login_container.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

.login .sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.login .login_container.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.login .overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.login .login_container.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.login .overlay {
  background: #2af598;
  background-image: linear-gradient(180deg, #F7941D 0%, #39B54A 100%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.login .login_container.right-panel-active .overlay {
  transform: translateX(50%);
}

.login .overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.login .overlay-left {
  transform: translateX(-20%);
}

.login .login_container.right-panel-active .overlay-left {
  transform: translateX(0);
}

.login .overlay-right {
  right: 0;
  transform: translateX(0);
}

.login .login_container.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.login .social-container {
  margin: 20px 0;
}

.login .social-container a {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}
/* 
.login footer {
  background-color: #222;
  color: #fff;
  font-size: 14px;
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 999;
}

footer p {
  margin: 10px 0;
}

footer i {
  color: red;
}

footer a {
  color: #3c97bf;
  text-decoration: none;
} */


@media only screen and (max-width: 767px) {
  .login .login_container {
        width: 100%;   
        overflow: unset;
    }
    .login .sign-in-container {
        width: 100%;
    }
    .login .overlay-container {
        top: 100%;
        left: 0;
        width: 100%;
    }
}

