@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&family=Roboto&display=swap');
/*font-family: 'Poppins', sans-serif;
font-family: 'Roboto', sans-serif;*/

* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    color: #03262c;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

h1 {
    font-size: 48px;
    line-height: 0.5;
    font-weight: 700;
}

h2 {
    font-size: 36px;
    line-height: 0.667;
    font-weight: 700;
    text-transform: uppercase;
}

h3 {
    font-size: 28px;
    text-transform: uppercase;
}

h4 {
    font-size: 18px;
    font-weight: 600;
    padding: 10px 0 25px 0;
}

embed,
iframe,
img,
object {
    max-width: 100%;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

a,
a:active,
a:focus,
a:hover,
button {
    text-decoration: none;
    outline: 0;
}

a:active,
a:focus,
a:hover {
    color: #007aff;
}

li a {
    color: #fff;
}

a:hover,
button:hover {
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

.section-title {
    font-size: 28px;
    font-weight: 600;
    color: #061738;
    text-transform: capitalize;
    margin-bottom: 20px;
}

.section-subtitle {
    font-size: 14px;
    font-weight: 400;
    color: #061738;
    text-transform: capitalize;
    font-family: "Roboto", sans-serif;
    width: 600px;
    margin: 0 auto;
}

.slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: #36970e;
}

.slick-dots li button:before {
    color: #36970e;
}

.slick-prev:before,
.slick-next:before {
    color: #fff;
}

.pt-80 {
    padding-top: 80px;
}

/*-------------------------------------------*/
/*Calsses css*/
/*-------------------------------------------*/
.hidden {
    display: none;
}

.position-bottom-right {
    position: absolute !important;
    bottom: 15px;
    right: 10px;
}

.position-bottom-left {
    position: absolute;
    bottom: 15px;
    left: 10px;
}

.position-top-right {
    position: absolute;
    top: 15px;
    right: 10px;
}

.position-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.rounded-icon {
    height: 50px;
    width: 50px;
    display: inline-block;
    text-align: center;
    background-color: #fff;
    border-radius: 50%;
    margin: 5px;
    cursor: pointer;
}

.rounded-icon i {
    font-size: 20px;
    line-height: 50px;
    color: #5cb917;
}

.btn {
    position: relative;
    color: #fff;
    z-index: 1;
    overflow: hidden;
    border: 1px solid #fff;
    border-radius: 35px;
    padding: 11px 30px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
}

.btn::after {
    content: "";
    position: absolute;
    height: 100%;
    z-index: -1;
    bottom: auto;
    background: #fcb700;
    
    top: 0;
    left: 0;
    width: 100%;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -ms-transition: 0.3s;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.btn:hover,
.btn:focus {
    color: #0f7404;
    border-color: #0f7404;
    outline: 0;
    background: #ffffff;
}

.btn:focus::after,
.btn:hover::after {
    height: 0;
}

.border-radius-0 {
    border-radius: 0;
}

.border-transparent {
    border-color: transparent;
}

.underline {
    text-decoration-line: underline;
}

/*-------------------------------------------*/
/*HEADER STYLE CSS START*/
/*-------------------------------------------*/
.sticky {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 999;
    border-color: #080808;
    box-shadow: 0 0 5px rgba(0, 0, 0, .8);
    transition: all 0.3s;
}


.header {
    position: fixed;
    width: 100%;
    top: 20px;
    left: 0;
    z-index: 9999;
}

.navbar {
    background: url(../img/header-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 10px 50px;
}

.navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0rem;
    width: 75px;
    margin-bottom: 0px;
}

.navbar-light .navbar-toggler {
    color: rgb(255, 255, 255);
    border-color: rgb(255, 255, 255);
    outline: transparent;
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
    color: #fff !important;
}

.navbar-light .navbar-nav .nav-link {
    color: #fff !important;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    position: relative;
    padding: 5px 20px;
    margin: 0px 2px;
    z-index: 1;
}

.navbar-light .navbar-nav .nav-link::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 0;
    width: 100%;
    background: linear-gradient(to right, #0f7404, #5cb917);
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    transition: all 0.3s;
    border-radius: 3px;
}

.navbar-light .navbar-nav .nav-link:focus::before,
.navbar-light .navbar-nav .nav-link:hover::before {
    height: 100%;
    visibility: visible;
    opacity: 1;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: #fafafa;
}

.header-content {
    display: inline-block;
    display: flex;
}

.header_contact {
    display: inline-block;
}

.header_contact span {
    color: #60cd12;
    display: block;
    font-weight: 700;
    font-size: 14px;
    margin-right: 10px;
}

.header_contact span.phone_no {
    color: #fff;
    font-size: 16px;
}

.header_icon {
    height: 45px;
    width: 45px;
    text-align: center;
    background-color: #13424a;
    border-radius: 50%;
    margin-left: 30px;
}

.header_icon i {
    color: #fff;
    font-size: 24px;
    line-height: 1.9;
}

.header_icon {
    display: inline-block;
    position: relative;
}

.header_icon .cart_no {
    position: absolute;
    top: 0px;
    right: -5px;
    height: 18px;
    width: 18px;
    text-align: center;
    background-color: #51af14;
    border-radius: 50%;
    font-size: 10px;
    font-weight: 600;
    color: #fff;
    line-height: 1.9;
}

.dropdown-menu {
    background-color: #03262c;
}

.dropdown-item {
    color: #ffffff;
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #ffffff;
    text-decoration: none;
    background-color: #1d8007;
}

/*-------------------------------------------*/
/*HEADER STYLE CSS END*/
/*-------------------------------------------*/

/*-------------------------------------------*/
/*BANNER STYLE CSS START*/
/*-------------------------------------------*/
.full-banner {
    padding: 285px 200px;
    background: url(../img/banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.banner-content h1 {
    font-size: 90px;
    line-height: 1.4;
    font-weight: 700;
}

.banner-content h1 span {
    color: #1d8007;
    font-weight: 600;
}

.banner-content h3 {
    color: #fff;
    font-size: 46px;
    font-weight: 600;
    background-color: #03262c;
    display: inline-block;
   padding-left: 20px;
   padding-right: 20px;
    text-transform: capitalize;
    text-align: center;
}

.banner-content p {
    font-size: 18px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    width: 500px;
    line-height: 1.6;
    margin-top: 20px;
}

.banner-content .btn {
    position: relative;
    color: #fff;
    z-index: 1;
    overflow: hidden;
    border: 1px solid transparent;
    border-radius: 35px;
    padding: 11px 30px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    margin-top: 15px;
}

.banner-content .btn::after {
    content: "";
    position: absolute;
    height: 100%;
    z-index: -1;
    bottom: auto;
    background: #00c6ff;
    background: -webkit-linear-gradient(to right, #0f7404, #5cb917);
    background: linear-gradient(to right, #0f7404, #5cb917);
    top: 0;
    left: 0;
    width: 100%;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -ms-transition: 0.3s;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.banner-content .btn:hover,
.banner-content .btn:focus {
    color: #0f7404;
    border-color: #0f7404;
    outline: 0;
}

.banner-content .btn:focus::after,
.btn:hover::after {
    height: 0;
}

/*-------------------------------------------*/
/*BANNER STYLE CSS END*/
/*-------------------------------------------*/

/*-------------------------------------------*/
/*FEATURES STYLE CSS END*/
/*-------------------------------------------*/
.full-features {
    padding: 100px 0px;
}

.features-box {
    background-color: transparent;
    border: 1.5px solid #e2f6de;
    padding: 100px 0px;
    transition: all 0.3s;
    position: relative;
    z-index: 1;
}

.features-box::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 175px;
    width: 175px;
    background: url(../img/letus-leaf.png);
    background-repeat: no-repeat;
    background-position: left top;
    opacity: 0;
    transition: all 0.3s;
    z-index: -1;
}

.features-box .features-icon-border {
    background-color: transparent;
    border-radius: 50%;
    border: 2px solid #e2f6de;
    padding: 13px;
    display: inline-block;
}

.features-box .features-icon {
    height: 65px;
    width: 65px;
    background-color: #e2f6de;
    margin: auto;
    border-radius: 50%;
}

.features-box .features-icon i {
    text-align: center;
    font-size: 37px;
    line-height: 1.8;
    color: #36970e;
}

.features-box h3 {
    color: #03262c;
    font-size: 24px;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    margin-top: 10px;
}

.features-box:hover {
    background-color: #03262c;
    border-color: #03262c;
}

.features-box:hover h3 {
    color: #fff;
}

.features-box:hover .features-icon {
    background-color: #36970e;
}

.features-box:hover .features-icon i {
    color: #fff;
}

.features-box:hover .features-icon-border {
    border-color: #36970e;
}

.features-box:hover h3 {
    color: #fff;
}

.features-box:hover::before {
    opacity: 1;
}

/*-------------------------------------------*/
/*FEATURES STYLE CSS END*/
/*-------------------------------------------*/
/*-------------------------------------------*/
/*ABOUT STYLE CSS START*/
/*-------------------------------------------*/

.full-about {
    min-height: 200px;
    background: url(../img/about-banner.jpg);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    padding: 180px 0;
    margin-bottom: 100px;
    position: relative;
    /*overflow: hidden;*/
}

.full-about .about-content h3 {
    color: #061738;
    font-size: 38px;
    font-weight: 600;
    display: inline-block;
    text-transform: capitalize;
}

.full-about .about-content h3 span {
    font-size: 35px;
    color: #0f7404;
}

.full-about .about-content .about-details {
    text-align: justify;
    text-justify: inter-word;
}

.full-about .about-content .about-details p {
    font-size: 16px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    /* width: 450px; */
    line-height: 1.6;
    margin-top: 20px;
    color: #061738;
}

.full-about .about-content .about-details p:first-child {
    font-weight: 600;
}

.about-icon-text {
    display: flex;
    margin-top: 20px;
}

.abt-icon {
    width: 15%;
}

.abt-text {
    width: 85%;
}

.about-icon-text .abt-icon {
    height: 80px;
    width: 80px;
    background-color: #e2f6de;
    border-radius: 50%;
    text-align: center;
    line-height: 98px;
}

.about-icon-text .abt-icon i {
    text-align: center;
    font-size: 50px;
    line-height: 1.6;
    color: #36970e;
    cursor: pointer;
}

.about-icon-text .abt-text h2 {
    font-size: 30px;
    line-height: 1.1;
    margin-left: 30px;
    margin-right: 0px;
    text-transform: capitalize;
    color: #061738;
}

.about-icon-text .abt-text h2 span {
    color: #1d8007;
}

.about-title h2 {
    font-size: 140px;
    font-weight: 700;
    color: #ffffff;
    position: absolute;
    top: 45%;
    left: -100px;
    z-index: 9;
    transform: rotate(-90deg);
}

/*-------------------------------------------*/
/*ABOUT STYLE CSS END*/
/*-------------------------------------------*/

/*-------------------------------------------*/
/*BEST SELLER PART CSS START*/
/*-------------------------------------------*/
.full-bestSeller {
    margin-bottom: 100px;
}

.product {
    position: relative;
    background-color: #fff;
    border: 1px solid #ececec;
    border-radius: 15px;
    overflow: hidden;
    padding: 10px;
    transition: all 0.3s;
}

.product-content {
    height: 100%;
    width: 100%;
    background: #fff;
    z-index: 1;
    border: 2px solid transparent;
}

/* .product-img {
    margin-bottom: 70px;
} */

.product-img img {
    border-radius: 15px;
}

.product-name {
    margin-top: 5px;
}

.product-name a {
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    color: #222;
    transition: all 0.3s;
    display: block;
    min-height: 46px;
}

.product-price {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fcb700;
    transition: all 0.3s;
}

.product-prev-price {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: #a4a9a0;
    margin-right: 5px;
    text-decoration: line-through;
    transition: all 0.3s;
}

.custom-btn {
    border-radius: 50px;
    padding: 5px 20px;
    text-transform: uppercase;
    font-weight: 600;
    /* background: linear-gradient(to right, #0f7404, #5cb917); */
    color: #fff;
    transition: all 0.3s;
    border-color: transparent;
}

.sold-out-tag {
    background-color: #ff4800;
    color: #fff;
    padding: 1px 12px;
    border-radius: 20px;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    transition: all 0.3s;
}

.icons {
    opacity: 0;
    transition: all 0.3s;
    width: 200px;
    text-align: center;
}

.product:hover {
    border: 1px solid #1d8007;
}

.product:hover .product-name a {
    color: #1d8007;
}

.product:hover .icons {
    opacity: 1;
}

/*-------------------------------------------*/
/*BEST SELLER PART CSS End*/
/*-------------------------------------------*/

/*-------------------------------------------*/
/*OFFER PART CSS End*/
/*-------------------------------------------*/
.full-offer {
    min-height: 500px;
    background: url(../img/offer-bg.jpg);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    padding: 160px 0 160px 0;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

/* .full-offer::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
}

.full-offer::after {
    content: "";
    position: absolute;
    bottom: -100px;
    left: 55%;
    transform: translateX(-50%);
    height: 650px;
    width: 650px;
    background: url(../img/fruits.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    z-index: -1;
} */
.offer_promo {
    text-align: center;
}

.offer_img {
    height: 300px;
    width: 300px;
    background: url(../img/round-shape-2.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    margin: 30px auto 0 auto;
}

.offer_img img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 37%;
    width: 27%;
}

.bg-1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 800px;
    width: 800px;
    background: url(../img/round-shape-1.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    z-index: -3;
}

.offer-content h3 {
    color: #54b215;
    font-size: 24px;
    font-weight: 700;
    text-transform: capitalize;
}

.offer-content h2 {
    color: #fff;
    font-size: 50px;
    font-weight: 700;
    text-transform: capitalize;
    line-height: 1.1;
}

.offer-content .btn {
    position: relative;
    color: #fff;
    z-index: 1;
    overflow: hidden;
    border: 1px solid #fff;
    border-radius: 35px;
    padding: 11px 30px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    border: 1px solid #0f7404;
    margin-top: 20px;
}

.offer-content .btn::after {
    content: "";
    position: absolute;
    height: 100%;
    z-index: -1;
    bottom: auto;
    background: #00c6ff;
    background: -webkit-linear-gradient(to right, #0f7404, #5cb917);
    background: linear-gradient(to right, #0f7404, #5cb917);
    top: 0;
    left: 0;
    width: 100%;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -ms-transition: 0.3s;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.offer-content .btn:hover,
.offer-content .btn:focus {
    color: #0f7404;
    border-color: #0f7404;
    outline: 0;
}

.offer-content .btn:focus::after,
.btn:hover::after {
    height: 0;
}

/*-------------------------------------------*/
/*OFFER PART CSS End*/
/*-------------------------------------------*/

/*-------------------------------------------*/
/*PARTNER PART CSS START*/
/*-------------------------------------------*/
.full-partners {
    background-color: #f6faf5;
    margin-bottom: 100px;
    padding: 50px 0;
}

/*-------------------------------------------*/
/*PARTNER PART CSS End*/
/*-------------------------------------------*/

/*-------------------------------------------*/
/*SLIDER CSS START*/
/*-------------------------------------------*/
.product-slider {
    position: relative;
}

.product-slider .slick-prev:before,
.product-slider .slick-next:before {
    color: #061738;
}

.product-slider .slick-next {
    position: absolute;
    top: -45px;
    right: 20px;
    text-align: center;
    line-height: 63px;
    color: #222;
}

.product-slider .slick-prev {
    position: absolute;
    top: -45px;
    left: auto;
    right: 66px;
    text-align: center;
    line-height: 63px;
    color: #222;
}

.slick-prev:before,
.slick-next:before {
    font-size: 36px !important;
}

/*-------------------------------------------*/
/*SLIDER CSS END*/
/*-------------------------------------------*/

/*-------------------------------------------*/
/*CONTACT NOW CSS START*/
/*-------------------------------------------*/
.contact-now {
    padding: 100px 0;
    position: relative;
    background: url(../img/contact-now-bg.jpg);
    background-position: bottom center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
    margin-bottom: 100px;
}

.contact-now::before {
    content: "";
    position: absolute;
    bottom: -100px;
    left: 0px;
    height: 100%;
    width: 345px;
    background: url(../img/leaf-2.png);
    background-position: center right;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
}

.contact-now::after {
    content: "";
    position: absolute;
    top: -100px;
    right: 0px;
    height: 100%;
    width: 315px;
    background: url(../img/leaf-3.png);
    background-position: center left;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
}

.contact-quote {
    font-size: 46px;
    width: 630px;
    font-weight: 600;
    color: #fff;
    text-transform: capitalize;
    margin: 0 auto;
}

.contact-now .btn {
    position: relative;
    color: #fff;
    z-index: 1;
    overflow: hidden;
    border: 1px solid #fff;
    border-radius: 35px;
    padding: 11px 30px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    border: 1px solid #0f7404;
    margin-top: 20px;
}

.contact-now .btn:hover,
.contact-now .btn:focus {
    color: #0f7404;
    border-color: #0f7404;
    outline: 0;
}

/*-------------------------------------------*/
/*CONTACT NOW CSS END*/
/*-------------------------------------------*/

/*-------------------------------------------*/
/*TESTIMONIAL NOW CSS START*/
/*-------------------------------------------*/
.full-testimonial {
    margin-top: 100px;
    margin-bottom: 100px;
}

.testimonial-text {
    min-height: 250px;
    background: url(../img/testimonial-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 75px 50px;
    color: #fff;
    text-align: right;
    position: relative;
}

.testimonial-info p {
    font-size: 23px;
    font-family: 'Poppins', sans-serif;
    line-height: 1.2;
    font-weight: 500;
}

.testimonial-info h3 {
    margin-top: 50px;
    text-transform: capitalize;
    color: #03262c;
    font-weight: 700;
    font-size: 30px;
}

.testimonial-info h4 {
    margin-top: 15px;
    text-transform: capitalize;
    color: #fff;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
}

.full-testimonial .icofont-double-right {
    position: absolute;
    top: 80%;
    left: 20%;
    height: 50px;
    width: 50px;
    text-align: center;
    border: 2px solid #07322a;
    border-radius: 50%;
    line-height: 47px;
    font-size: 20px;
    color: #07322a;
    z-index: 1;
    cursor: pointer;
}

.full-testimonial .icofont-double-right:hover {
    border-color: #fff;
    background-color: #fff;
    color: #07322a;
}

.full-testimonial .icofont-double-left {
    position: absolute;
    top: 80%;
    left: 10%;
    height: 50px;
    width: 50px;
    text-align: center;
    border: 2px solid #07322a;
    border-radius: 50%;
    line-height: 47px;
    font-size: 20px;
    color: #07322a;
    z-index: 1;
    cursor: pointer;
}

.full-testimonial .icofont-double-left:hover {
    border-color: #fff;
    background-color: #fff;
    color: #07322a;
}

/*-------------------------------------------*/
/*TESTIMONIAL NOW CSS END*/
/*-------------------------------------------*/

/*-------------------------------------------*/
/*NEWS PART CSS START*/
/*-------------------------------------------*/
.full-latest-news {
    margin-bottom: 100px;
}

.news-content .date-react .date {
    color: #1fa60f;
    font-size: 18px;
    font-weight: 500;
    margin-right: 40px;
}

.news-content .date-react .react {
    color: #03262c;
    font-size: 18px;
    font-weight: 500;
    margin-right: 20px;
}

.news-content .date-react .react i {
    color: #1fa60f;
    font-size: 18px;
}

.news-content .news-title h4 {
    font-size: 28px;
    color: #061738;
    margin-top: 15px;
}

.news-content {
    padding: 30px;
    border-left: 2px solid #dcedd8;
    border-right: 2px solid #dcedd8;
    border-bottom: 2px solid #dcedd8;
}

.latest-news-list .news-content {
    padding: 23px;
    border-left: 0px;
    border-top: 2px solid #dcedd8;
    border-right: 2px solid #dcedd8;
    border-bottom: 2px solid #dcedd8;
}

.latest-news-list .news-content .news-title h4 {
    font-size: 24px;
    color: #061738;
    margin-top: 15px;
}

.latest-news-list {
    margin-top: 30px;
}

.latest-news-list:first-child {
    margin-top: 0px;
}

.latest-news-list .news-img {
    width: 370px;
}

.latest-news-list .news-content .news-title h4 {
    font-size: 24px;
    color: #061738;
    margin-top: 15px;
    vertical-align: middle;
}

/*-------------------------------------------*/
/*NEWS PART CSS END*/
/*-------------------------------------------*/

/*====================================================================================================*/

/*------------------------------------------*/
/* HERO SECTION PART CSS START*/
/*------------------------------------------*/
.hero_section {
    background: url(../img/product_hero_section_bg.jpg);
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    padding-top: 150px;
    padding-bottom: 120px;
    position: relative;
}

.png_img {
    position: absolute;
    width: 200px;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
}

.herosection_content h2 {
    font-size: 48px;
    font-weight: 700;
    line-height: 1.5;
    padding-bottom: 10px;
    text-transform: capitalize;
}

/*------------------------------------------*/
/*HERO SECTION PART CSS END*/
/*------------------------------------------*/

/*------------------------------------------*/
/* ORDER PART CSS START */
/*------------------------------------------*/
.order_part {
    padding: 100px 0;
}

.order_txt h2 {
    font-size: 28px;
    font-weight: 700;
    padding-bottom: 30px;
    color: #232323;
}

.order_txt p {
    font-size: 16px;
    font-weight: 400;
    color: #555555;
    padding-top: 10px;
}

.card_total,
.payment_head {
    border: none;
    background-color: #03262c;
    font-size: 18px;
    color: #fff;
    width: 100%;
    height: 100%;
    text-align: center;
}

.total_num {
    color: #54b215;
    font-weight: 700;
}

.payment_head {
    background: #e2f6de;
    color: #232323;
}

.billing_head h2 {
    font-size: 28px;
    font-weight: 700;
    padding-bottom: 30px;
    color: #232323;
}

.form-control {
    color: #fff !important;
    transition: all 0.3s;
    padding: 10px 20px;
    height: 50px;
}

.form-control:focus {
    border-color: transparent;
    background-color: #03262c;
    box-shadow: none;
}

.form-control:focus::placeholder {
    color: #fff;
}

.payment_method .form-check label.form-check-label {
    margin-left: 10px;
}

/*------------------------------------------*/
/* ORDER PART CSS END */
/*------------------------------------------*/

/*------------------------------------------*/
/*ERROR PART CSS START*/
/*------------------------------------------*/

.error_part {
    padding-top: 80px;
}

.error_content h4 {
    font-size: 46px;
    font-weight: 700;
    padding-bottom: 15px;
    color: #03262c;
}

.error_content p {
    font-size: 18px;
    font-weight: 400;
    color: #727474;
    line-height: 1.5;
    margin-bottom: 20px;
}

/*------------------------------------------*/
/*ERROR PART CSS END*/
/*------------------------------------------*/

/*------------------------------------------*/
/* BLOG PART CSS START */
/*------------------------------------------*/
.blog_part {
    padding: 100px 0;
}

.post_category {
    padding: 20px 30px;
    border: 2px solid #e9f8e6;
    margin-top: 50px;
}

.search_ber {
    position: relative;
}

.search_button {
    height: 50px;
    padding-right: 55px;
}

.search_ber i {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    background: linear-gradient(to right, #0f7404, #5cb917);
    color: #fff;
    text-align: center;
    line-height: 50px;
    font-weight: 600;
    font-size: 16px;
    border-radius: 4px;
}

.post_head h3 {
    padding-bottom: 15px;
    font-size: 24px;
    font-weight: 700;
    color: #03262c;
}

.post_category ul li {
    position: relative;
    transition: all 0.3s;
    z-index: 1;
}

.post_category ul li a {
    color: #03262c;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s;
}

.post_category ul li::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(to right, #0f7404, #5cb917);
    z-index: -1;
    opacity: 0;
    transition: all 0.3s;
}

.post_category ul li:hover {
    color: #fff;
    border-color: transparent !important;
}

.post_category ul li:hover a {
    color: #fff;
}

.post_category ul li:hover::before {
    opacity: 1;
}

.post_category ul li {
    border: 2px solid #e9f8e6 !important;
    margin-top: 8px;
}

.post_category ul li i {
    padding-right: 12px;
    font-size: 16px;
}

.recent_post,
.populer_tag {
    margin-top: 50px;
    padding: 20px 30px;
    border: 2px solid #e9f8e6;
}

.sidebar h4 {
    padding-bottom: 25px;
    color: #03262c;
    font-weight: 700;
    font-size: 24px;
    border-bottom: 2px solid #e9f8e6;
}

.recent_post ul li {
    padding-top: 10px;
    border-bottom: 1px solid #e9f8e6;
}

.recent_post ul li:last-child {
    border-bottom: 0;
}

.recent_post ul li span {
    font-size: 16px;
    color: #5cb917;
    font-weight: 600;
}

.recent_post ul li h4 a {
    font-size: 24px;
    font-weight: 600;
    padding-top: 10px;
    line-height: 1.2;
    padding-bottom: 10px;
    color: #03262c;
}

.populer_btn ul li {
    font-size: 16px;
    color: #03262c;
    padding: 10px 30px;
    border: 1px solid #e9f8e6;
    display: inline-block;
    text-align: center;
    margin-top: 5px;
    font-weight: 500;
    transition: all 0.3s;
    cursor: pointer;
}

.populer_btn ul li:hover {
    border: 1px solid #5cb917;
}

.date span {
    color: #03262c;
    font-weight: 600;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
}

.date span i {
    font-size: 16px;
    color: #5cb917;
    padding-right: 10px;
}

.fav_icon span {
    font-size: 16px;
    padding-right: 15px;
    font-weight: 500;
}

.fav_icon span i {
    font-size: 16px;
    padding-right: 5px;
    color: #5cb917;
}

.blog_title h4 a {
    font-size: 28px;
    color: #03262c;
    font-weight: 600;
    padding: 15px 0;
    line-height: 1.2;
    /*text-transform: capitalize;*/
}

.blog_title a {
    padding-top: 20px;
    color: #5cb917;
    font-size: 16px;
    font-weight: bold;
}

.pages_num ul li {
    margin: 2px;
    display: inline-block;
    width: 45px;
    height: 45px;
    border: 1px solid #dde7db;
    border-radius: 50%;
    line-height: 43px;
    transition: all 0.3s;
    cursor: pointer;
    position: relative;
}

.pages_num ul li a {
    font-size: 16px;
    color: #222;
    font-weight: 600;
    transition: all 0.3s;
}

.pages_num ul li:hover {
    background: linear-gradient(to right, #0f7404, #5cb917);
}

.pages_num ul li:hover a {
    color: #fff;
}

/*------------------------------------------*/
/*BLOG PART CSS END */
/*------------------------------------------*/

/*------------------------*/
/* BLOG DETAILS PART CSS START */
/*------------------------*/

.blogdetails_part {
    padding: 100px 0;
}

.blog_details p {
    color: #434748;
    font-size: 15px;
    font-weight: 400;
    padding: 8px 0;
    line-height: 1.6;
    font-family: "Roboto", sans-serif;
}

.blog_details_comment {
    background: url(../img/details_bg1.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 35px 25px;

}

.blog_details_comment p {
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    color: #03262c;
    line-height: 1.2;
}

.blog_details strong,
.details_comment strong {
    font-size: 30px;
    font-weight: 600;
}

.social_iconber {
    padding: 10px 15px;
    background: #e2f6de;
}

.tags span {
    font-size: 17px;
    color: #575564;
    font-family: "Roboto", sans-serif;
}

.tags span a {
    font-size: 16px;
    color: #575564;
}

.tags span:first-child {
    font-size: 17px;
    color: #03262c;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
}

.tags span:hover a {
    color: #57b416;
}

.social_iconber ul li {
    display: inline-block;
    height: 40px;
    width: 40px;
    background-color: transparent;
    text-align: center;
    line-height: 38px;
    border-radius: 50%;
    border: 1.5px solid #03262c;
    transition: all 0.3s;
}

.social_iconber ul li:hover {
    background-color: #127605;
    border-color: transparent;
}

.social_iconber ul li:hover a {
    color: #fff;
}

.social_iconber ul li a {
    color: #03262c;
    font-size: 16px;
    font-weight: 600;
    transition: all 0.3s;
}

.customer_img {
    width: 250px;
}

.customer_info {
    margin-left: 15px;
}

.customer_info strong {
    font-size: 20px;
    font-weight: 700;
}

.customer_info span {
    font-size: 16px;
    color: #5cb917;
    font-weight: 500;
}

.customer_info p {
    font-size: 16px;
    line-height: 1.4;
}

.reply {
    font-size: 16px;
    font-weight: 600;
}

.reply i {
    padding-right: 5px;
}

.blog_form {
    padding: 60px;
    background: #e2f6de;
}

.blogdetails_textarea {
    width: 100%;
    height: 200px;
}

/*------------------------------------------*/
/* BLOG DETAILS PART CSS END */
/*------------------------------------------*/

/*------------------------------------------*/
/* PRODUCT DETAILS PART CSS START */
/*------------------------------------------*/
.product_details {
    padding: 15px 0;
}

.small_img {
    margin-right: 20px;
    width: 100px;
}

.small_img img {
    display: block;
    margin-bottom: 20px;
}

.producudetails_content h3 {
    font-size: 30px;
    font-weight: 600;
    padding-bottom: 10px;
    text-transform: capitalize;
}

.customer_review {
    margin-bottom: 10px;
}

.customer_review ul li i {
    color: #ffa300;
    font-size: 16px;
}

.customer_review ul li {
    display: inline-block;
    padding-right: 50px;
}

.producudetails_content strong {
    padding-right: 15px;
    font-size: 24px;
    font-weight: 600;
    padding-top: 20px;
    color: #fcb700;
}

.producudetails_content strong del {
    color: #bcbcbc;
}

.producudetails_content p {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.8;
    margin-top: 11px;
    margin-bottom: 11px;
    font-family: "Roboto", sans-serif;
    color: #3d4555;
}

.add_to_cart {
    margin: 40px 0;
    align-items: center;
}

.quantity {
    width: 80px;
    padding: 9px 10px;
    background: transparent;
    text-align: center;
    border: 2px solid #bcbcbc;
    color: #061738;
}

.quantity button {
    color: #061738;
    background-color: transparent;
    border: 0;
}

.quantity button:hover,
.quantity button:focus {
    color: #1a7d06;
    border: 0;
    outline: 0;
}

.quantity_num {
    margin: 0px 10px;
}

/*------------------------------------------*/
/* PRODUCT DETAILS PART CSS END*/
/*------------------------------------------*/

/*------------------------------------------*/
/* PRODUCT DETAILS TABBER PART CSS START */
/*------------------------------------------*/
.product_tabber {
    padding: 60px 0px;
}

.tab-content {
    padding-top: 10px;
}
.nav_custom {
    width: 100%;
    background: #e2f6de;
    margin-bottom: 20px;
    border-bottom: 2px solid #5cb917;
}

.tab-pane p {
    font-size: 14px;
    font-weight: 400;
    padding: 10px 0px;
    line-height: 1.8;
    font-family: "Roboto", sans-serif;
}

.nav-tabs .nav-link:focus {
    border: none;
    border-color: transparent;
}

.nav-tabs .nav-link.nav-link-custom {
    color: #03262c;
    font-weight: 600;
    border: 0;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-radius: 0;
}

.nav-tabs .nav-link.nav-link-custom:hover {
    color: #fff;
    background-color: #03262c;
    border-color: #03262c #03262c #03262c;
}

.nav-tabs .nav-link.nav-link-custom.active {
    color: #fff;
    background-color: #03262c;
    border-color: #03262c #03262c #03262c;
}

/*------------------------------------------*/
/* PRODUCT DETAILS TABBER PART CSS END */
/*------------------------------------------*/

/*------------------------------------------*/
/* CART OVERVIEW PART CSS START */
/*------------------------------------------*/
.cart_overview {
    padding: 100px 0;
}

.cartoverview_title {
    background: #e2f6de;
    border: 0;
}

.search_ber a {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    width: 120px;
    height: 42px;
    background: linear-gradient(to right, #0f7404, #5cb917);
    color: #fff;
    text-align: center;
    line-height: 42px;
    font-weight: 600;
    font-size: 16px;
    transition: all 0.3s;
}

.coupon_part {
    padding-bottom: 80px;
}

/*------------------------------------------*/
/* CART OVERVIEW PART CSS END */
/*------------------------------------------*/

/*------------------------------------------*/
/*FOOTER TOP PART CSS START */
/*------------------------------------------*/
.footer_top {
    background: url(../img/product_footer_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    padding-top: 200px;
    padding-bottom: 100px;
}

.footer_widget_title h2 {
    font-weight: 700;
    font-size: 28px;
    color: #fff;
    text-transform: capitalize;
    line-height: 1.1;
}
.footer__widget-title h4 {
    margin-bottom: 0 !important;
}

.footer_widget_content li:not(.email_content) {
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    text-transform: capitalize;
    margin-bottom: 5px;
    font-family: "Roboto", sans-serif;
}

.email_content {
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 5px;
    font-family: "Roboto", sans-serif;
}

.footer_widget_content li span {
    color: #54b215;
    display: block;
}

.footer_social_icons li {
    display: inline-block;
    margin-top: 40px;
    margin-left: 3px;
    margin-right: 3px;
}

.footer_social_icons li:first-child {
    margin-left: 0;
}

.footer_social_icons li:last-child {
    margin-right: 0;
}

.footer_social_icons li a i {
    text-align: center;
    font-size: 16px;
    background: #fff;
    border-radius: 50%;
    color: black;
    transition: all 0.3s;
    padding: 10px;
}

.footer_social_icons li a i:hover {
    background-color: #0f7404;
    color: #fff;
}

.footer_widget_content li {
    padding: 2px 0;
}

.footer_widget_content li:hover a {
    color: #58b516;
}

.footer_widget_content li i {
    font-size: 16px;
    color: #fff;
    padding-right: 15px;
    transition: all 0.3s;
}

.footer_widget_content li:hover i {
    color: #58b516;
}

/*------------------------------------------*/
/*FOOTER TOP PART CSS END*/
/*------------------------------------------*/

/*------------------------------------------*/
/*FOOTER BOTTOM PART CSS START*/
/*------------------------------------------*/
.footer_bottom {
    /* background: linear-gradient(to right, #0f7404, #5cb917); */
    background: #03262c url(../img/grass.png) no-repeat;
    position: relative;
}

.footer_txt p {
    font-size: 16px;
    color: #d6dbe6;
    padding-top: 15px;
    font-weight: 500;
    padding-bottom: 15px;
    margin-bottom: 0;
}

.footer_txt a {
    color: #fff !important;
}

.footer_txt p span {
    color: #fff;
}

/*------------------------------------------*/
/*FOOTER BOTTOM PART CSS END*/
/*------------------------------------------*/

.truck {
    /* animation properties */
    animation: mymove 15s infinite;
    animation-timing-function: linear;
    position: absolute;
    bottom: 0;
}

@keyframes mymove {
    from {
        right: 0px;
    }

    to {
        right: 100%;
    }
}

.pad-80 {
    padding: 80px 0px;
}

.sidebar-wrapper {
    background: #fff;
    border: 1px solid #ececec;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
}

.sidebar-wrapper h5 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
}

.sidebar-list li label {
    margin-left: 5px;
}

input[type=range] {
    -webkit-appearance: none;
    margin: 20px 0;
    width: 100%;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    animate: 0.2s;
    background: #0f7404;
    border-radius: 25px;
}

input[type=range]::-webkit-slider-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 1);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -8px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: #0f7404;
}

.range-wrap {
    width: 100%;
    position: relative;
}

.range-value {
    position: absolute;
    top: -50%;
}

.range-valueX {
    right: 10px;
}

.range-value span {
    width: 30px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    background: #0f7404;
    color: #fff;
    font-size: 12px;
    display: block;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 6px;
}

.range-value span:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid #0f7404;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    margin-top: -1px;
}

.price-slide.range-slider {
    margin-top: 30px;
}

.grams {
    margin-left: 10px;
}

input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

.number-input {
    border: 1px solid #ddd;
    display: inline-flex;
}

.number-input,
.number-input * {
    box-sizing: border-box;
}

.number-input button {
    outline: none;
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin: 0;
    position: relative;
}

.number-input button:before,
.number-input button:after {
    display: inline-block;
    position: absolute;
    content: '';
    width: 1rem;
    height: 2px;
    background-color: #212121;
    transform: translate(-50%, -50%);
}

.number-input button.plus:after {
    transform: translate(-50%, -50%) rotate(90deg);
}

.number-input input[type=number] {
    font-family: sans-serif;
    max-width: 100px;
    padding: .5rem;
    border: 1px solid #ddd;
    border-top: 0;
    border-bottom: 0;
    font-size: 22px;
    width: 60px;
    height: 40px;
    font-weight: bold;
    text-align: center;
}

.product-flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.product-flex .left {
    width: 60%;
}

.product-flex .right {
    width: 40%;
}

.product-flex .right button {
    margin: 0 0 0 auto;
    display: table;
}

/*thulasi*/
.conditions p {
    margin-bottom: 20px;
}

.conditions h2 {
    margin: 15px 0;
}

.conditions h3 {
    margin: 20px 0 10px 0;
}

.terms_section {
    padding: 80px 0;
}

.conditions ul li {
    list-style: disc;
    margin-left: 30px;
}

.policies p {
    margin-bottom: 20px;
}

.policies h2 {
    margin: 15px 0;
}

.policies h3 {
    margin: 20px 0 10px 0;
}

.privacy_section {
    padding: 80px 0;
}

.about-image {
    position: relative;
    min-height: 426px;
}

.about-image img {
    width: 100%;
    border-radius: 30px;
    position: absolute;
    bottom: 0;
}

.about-content h2 {
    margin-bottom: 20px;
}

.about-content {
    padding-top: 20px;
}

.about-content h3 {
    font-weight: 600;
    text-transform: capitalize;
}

.about-content p {
    font-size: 16px;
}

.align {
    align-items: center;
}

.benefits {
    background-color: #f1f1f1;
    padding: 80px;
}

.benefits .benefit-image {
    margin: 30px 0;
}

.benefits .card {
    margin: 30px 0;
}

.transition,
p,
ul li i:before,
ul li i:after {
    transition: all 0.25s ease-in-out;
}

.flipIn,
h1,
ul li {
    animation: flipdown 0.5s ease both;
}

.no-select,
h2 {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.mission h1,
.mission h2 {
    color: #60cd12;
}

.benefit-image img {
    border-radius: 30px;
    height: 700px;
    width: 100%;
}

.benefits .card-body h3 {
    margin-bottom: 20px;
}

.mission {
    padding: 80px;
}

.mission h1 {
    text-transform: uppercase;
    font-size: 36px;
    line-height: 42px;
    letter-spacing: 3px;
    font-weight: 100;
}

.mission h2 {
    font-size: 26px;
    line-height: 34px;
    font-weight: 300;
    letter-spacing: 1px;
    display: block;
    background-color: #fefffa;
    margin: 0;
    cursor: pointer;
}

.mission p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 1px;
    position: relative;
    overflow: hidden;
    max-height: 800px;
    opacity: 1;
    transform: translate(0, 0);
    margin-top: 14px;
    z-index: 2;
}

.mission ul {
    list-style: none;
    perspective: 900;
    padding: 0;
    margin: 0;
}

.mission ul li {
    position: relative;
    padding: 0;
    margin: 0;
    padding-bottom: 10px;
    padding-top: 10px;
    border-top: 1px dotted #dce7eb;
}

.mission ul li:nth-of-type(1) {
    animation-delay: 0.5s;
}

.mission ul li:nth-of-type(2) {
    animation-delay: 0.75s;
}

.mission ul li:nth-of-type(3) {
    animation-delay: 1.0s;
}

.mission ul li:last-of-type {
    padding-bottom: 0;
}

.mission ul li i {
    position: absolute;
    transform: translate(-6px, 0);
    margin-top: 16px;
    right: 0;
}

.mission ul li i:before,
.mission ul li i:after {
    content: "";
    position: absolute;
    background-color: #60cd12;
    width: 3px;
    height: 9px;
}

.mission-right ul li i:before {
    transform: translate(-2px, 0) rotate(45deg);
}

.mission-right ul li i:after {
    transform: translate(2px, 0) rotate(-45deg);
}

.mission-right ul li input[type=checkbox] {
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
}

.mission-right ul li input[type=checkbox]:checked~p {
    margin-top: 0;
    max-height: 0;
    opacity: 0;
    transform: translate(0, 50%);
}

.mission-right ul li input[type=checkbox]:checked~i:before {
    transform: translate(2px, 0) rotate(45deg);
}

.mission-right ul li input[type=checkbox]:checked~i:after {
    transform: translate(-2px, 0) rotate(-45deg);
}

@keyframes flipdown {
    0% {
        opacity: 0;
        transform-origin: top center;
        transform: rotateX(-90deg);
    }

    5% {
        opacity: 1;
    }

    80% {
        transform: rotateX(8deg);
    }

    83% {
        transform: rotateX(6deg);
    }

    92% {
        transform: rotateX(-3deg);
    }

    100% {
        transform-origin: top center;
        transform: rotateX(0deg);
    }
}

.card {
    height: 700px;
    border-radius: 40px;
    background-color: #004318;
    flex-direction: row;
    align-items: flex-end;
    padding: 20px;
    color: white;
}

.card h3 {
    font-size: 55px;
}

.card p {
    font-size: 20px;
    font-weight: 400;
}

.contact-form {
    padding: 80px;
}

.left-form input[type=text],
textarea {
    margin-bottom: 30px;
    width: 100%;
    padding: 12px;
    border: 1px solid #004318;
    border-radius: 4px;
    box-sizing: border-box;
}

.left-form input[type=number] {
    margin-bottom: 30px;
    width: 100%;
    padding: 12px;
    border: 1px solid #004318;
    border-radius: 4px;
    box-sizing: border-box;
}

.contact-form h2 {
    margin-bottom: 30px;
    color: #004318;
}

.hr {
    border: 2px solid;
    width: 20%;
    margin: 30px 0;
}

.right-form {
    padding-left: 50px;
}

.right-form li {
    padding-bottom: 30px;
}

.right-form span {
    padding-left: 25px;
}

.right-form li a {
    position: relative;
    display: inline-block;
}

.right-form i {
    position: absolute;
    left: 0;
    top: 4px;
}

.map-dig {
    width: 100%;
    height: 300px;
}

.submit-btn {
    width: 90px;
    border-radius: 4px;
    background-color: #004318;
    color: white;
    padding: 5px;
    border: 0;
}

.submit-btn:hover {
    background-color: #14b64d;
}

/*thulasi*/

/* My Account Page */
.sub_heading {
    font-weight: 700;
}

.user_dashboard_pages {
    margin-top: 160px;
    margin-bottom: 100px;
}

.user_dashboard .myaccounts_sidebar {
    border-radius: 5px;
    box-shadow: 0px 3px 6px #d6d6d6;
}

.user_dashboard .myaccounts_sidebar .account_nav ul li {
    padding: 10px 20px;
    border-bottom: 1px solid #d6d6d6;
}

.user_dashboard .myaccounts_sidebar .account_nav ul li a {
    color: #080808;
}

.user_dashboard .myaccounts_sidebar .account_nav ul li a.active {
    color: #0f7404;
}

.user_dashboard .dashboard_area {
    padding: 30px;
    background: #fff;
    box-shadow: 0px 3px 6px #d6d6d6;
    border-radius: 10px;
}

.user_dashboard .dashboard_area .myaccount_edit {
    align-items: flex-start;
    justify-content: space-between;
}

.user_dashboard .dashboard_area .myaccount_edit .right a {
    width: 80px;
    height: 80px;
    margin: 0 0 0 auto;
    display: block;
    border-radius: 50%;
    position: relative;
}

.user_dashboard .dashboard_area .myaccount_edit .right a img {
    width: 100%;
}

.user_dashboard .dashboard_area .myaccount_edit .right a:hover {
    position: relative;
    z-index: 9;
}

.user_dashboard .dashboard_area .myaccount_edit .right a:hover::after {
    background: rgba(0, 0, 0, 0.5);
    background-image: url(../img/pen.png);
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.user_dashboard .dashboard_area .myaccount_edit .create_question a.create_btn {
    background: #1d8007;
    color: #fff;
    width: 200px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 30px;
    display: block;
}

.user_dashboard .dashboard_area .profile_form .form_group {
    justify-content: space-between;
    margin: 25px 0px;
}

.user_dashboard .dashboard_area .profile_form .form_group .col {
    width: 48%;
}

.user_dashboard .dashboard_area .profile_form .form_group .form_field label {
    display: block;
    margin-bottom: 10px;
}

.user_dashboard .dashboard_area .profile_form .form_group .form_field input,
.user_dashboard .dashboard_area .profile_form .form_group .form_field select {
    width: 100%;
    height: 45px;
    border-radius: 5px;
    background: #f7fff9;
    box-shadow: 0px 3px 3px #d6d6d6;
    border: 0;
    text-indent: 15px;
    outline: none;
}

.user_dashboard .dashboard_area .profile_form .form_group .form_field input[type="file"],
.user_dashboard .dashboard_area .profile_form .form_group .form_field select[type="file"] {
    line-height: 40px;
}

.user_dashboard .dashboard_area .profile_form .form_group .form_field select {
    font-size: 16px;
    line-height: 1;
    border: 0;
    border-radius: 5px;
    height: 45px;
    background: url(../img/down-arrow.png) no-repeat right #f7fff9;
    -webkit-appearance: none;
    background-position-x: 96%;
    outline: 0;
}

.user_dashboard .dashboard_area .profile_form .form_group .form_field textarea {
    display: block;
    background: #f7fff9;
    box-shadow: 0px 3px 3px #d6d6d6;
    border: 0;
    height: 150px;
    width: 100%;
    border-radius: 15px;
    outline: 0;
    padding: 10px;
}

.user_dashboard .dashboard_area .profile_form .form_group.col-full {
    width: 100%;
}

.user_dashboard .dashboard_area .profile_form .form_group.col-full .form_field {
    width: 100%;
}

.user_dashboard .dashboard_area .profile_form .form_group .submit_btn {
    background: #1d8007;
    color: #fff;
    border-radius: 15px;
    border: 0;
    width: 140px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    margin-left: 15px;
    box-shadow: 0px 3px 3px #d6d6d6;
}

.user_dashboard .dashboard_area .profile_form .kyc {
    border-top: 1px solid #d6d6d6;
    margin-top: 50px;
    padding-top: 50px;
}

.user_dashboard .dashboard_area .profile_form .kyc h3 {
    padding-bottom: 10px;
}

.user_dashboard .dashboard_area .question_lists {
    margin-top: 40px;
}

.user_dashboard .dashboard_area .question_lists .lists {
    background: #f1f1f1;
    border-radius: 5px;
    margin: 10px 0px;
    position: relative;
    align-items: center;
}

.user_dashboard .dashboard_area .question_lists .lists .left {
    width: 16%;
    padding: 15px 15px;
}

.user_dashboard .dashboard_area .question_lists .lists .left img {
    border-radius: 10px;
}

.user_dashboard .dashboard_area .question_lists .lists .left p {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
}

.user_dashboard .dashboard_area .question_lists .lists .right {
    background: var(--theme-color-green-light);
    width: 80%;
    height: 100%;
    text-align: left;
    font-size: 13px;
    margin-top: 0px;
}

.user_dashboard .dashboard_area .question_lists .lists .right h4 {
    padding-bottom: 6px;
}

.user_dashboard .dashboard_area .question_lists .lists .right span {
    width: 100%;
    display: block;
    font-size: 14px;
}

.user_dashboard .dashboard_area .question_lists .lists a {
    width: 140px;
    background: #07322a;
    color: #fff;
    text-align: center;
    height: 40px;
    line-height: 40px;
    margin: auto 20px auto 0;
    border-radius: 6px;
}

.user_dashboard .dashboard_area .question_lists .lists a:hover {
    background: #0f7404;
}

.myaccount_menus ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.myaccount_menus ul li {
    display: inline-block;
    margin: 0px 0px 0px 15px;
    color: #fff;
}

.myaccount_menus ul li a {
    color: #fff;
}

.myaccount_menus ul li a.profile img {
    width: 30px;
    height: 30px;
}

.myaccount_menus ul li ul.dropdown {
    display: none;
    position: absolute;
    top: 70px;
    right: 0;
    background: #03262c;
    width: 200px;
    z-index: 9;
}

.myaccount_menus ul li ul.dropdown li {
    width: 100%;
    margin: 10px 0px;
    padding: 0px 15px;
}

.myaccount_menus ul li ul.dropdown li a {
    font-size: 14px;
    color: #fff;
    ;
}

.myaccount_menus ul li ul.dropdown li a:hover {
    color: #0f7404;
}

form .error {
    color: red !important;
}

/* } */
.border-radius-0 {
    border-radius: 0;
}

/* My Account Page */

.custom-btn :hover {
    color: #000 !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.payment_type {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.strike-through {
    text-decoration: line-through;
}

.payment_type label {
    padding: 15px;
    box-shadow: 1px 1px 1px 1px lightgreen;
    width: 100%;
}

.Toastify {
    position: relative;
    z-index: 10000;
}
.truncate {
    display: inline-block;
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .truncate + a {
  display: inline-block;
    vertical-align: top;
    margin-top: -1px;
  }

  .special{
    font-size: 14px;
    color: white;
    font-weight: 500;
    text-transform: uppercase;
    background: #fcb700;
    padding: 10px 25px;
  }
  .main-menu ul li{
    margin-right: 214px !important;
  }
  .header__currency select, .header__lang select {
    border: none !important;
    padding: 3px 2px;
}
.header__lang select{
    text-transform: uppercase;
}
.header__welcome{
    text-align: center;
    text-transform: uppercase;
    font-size: 18px;
    color: #fcb700;
    font-weight: 500;
}
.header__top{
    padding: 10px;
}
.foot{
    display: flex;
}
.foot-row{
    justify-content: space-between;
}
.footer__widget{
    margin-bottom: 0 !important;
}
.footer__widget h4{
    color: black !important;
}
.footer__widget span{
    color: black !important;
}
.footer__widget ul li a{
    color: black !important;
}
.footer-bg{
    background: white !important;
}
.footer__widget p{
    margin-bottom: 5px !important;
}
.footer__widget-title h4{
    padding: 15px 0 10px 0;
}
.footer__copyright-text p{
    text-align: center;
}
.social__media svg{
    font-size: 30px;
    color: black;
    padding-right: 10px;
}
.footer__copyright{
    background: white !important;
}

.static img{
    width: 100%;
    height: 250px;
    object-fit: cover;
}
.slide img{
    width: 100%;
    height: 500px;
    object-fit: cover;
}
.padding{
    padding: 0 !important;
}